import api from '../services/api';

export const getAllUserwhitelistRequest = async (
  token: string,
  search: string
): Promise<any> => {
  const result = await api.get(`/api/user-whitelist?search=${search}`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });

  return result?.data;
};

export const handleDeleteWhitelistRequest = async (
  id: string,
  token: string
): Promise<any> => {
  const result = await api.delete(`/api/user-whitelist/${id}`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });

  return result?.data;
};

export const submitAddWhitelist = async (
  value: string,
  partner: string,
  token: string
): Promise<any> => {
  const result = await api.post(
    '/api/user-whitelist',
    { value, partner },
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }
  );

  return result?.data;
};

export const handleChangeWhitelistStatusRequest = async (
  status: boolean,
  token: string
): Promise<any> => {
  const result = await api.put(
    '/api/user-whitelist/status',
    { status },
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }
  );

  return result?.data;
};

export const uploadFileWhitelist = async (
  files: FileList,
  token: string
): Promise<any> => {
  const formData = new FormData();

  // eslint-disable-next-line no-restricted-syntax
  for (const file of Array.from(files)) {
    formData.append('files', file);
  }

  const result = await api.post(`/api/user-whitelist/upload-file`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${token}`,
    },
  });

  return result?.data;
};
