/* eslint-disable no-underscore-dangle */
import * as React from 'react';
import { CircularProgress, Modal, Typography } from '@mui/material';
import { toast } from 'react-toastify';
import {
  Div9,
  FormLoginStyled,
  StyledBox,
  StyledContentButtons,
} from './styles';
import SimpleInput from '../../../Login/Input';
import SubmitButton, { Button } from '../../../../components/Button';
import NewSelect from '../../../NewRequestV3/StepOne/Form/Select';
import { cpfMask, removeMask } from '../../../../utils/cpf';

interface Props {
  open: boolean;
  title: string;
  isLoading: boolean;
  partners: any[];
  defaultValues?: {
    identification: string;
    partner: string;
  };
  handleClose: () => void;
  handleSubmit: (values: any) => void;
}

const ModalAddWhitelist = ({
  open,
  title,
  isLoading,
  defaultValues,
  partners,
  handleClose,
  handleSubmit,
}: Props): React.ReactElement => {
  const [values, setValues] = React.useState({
    identification: '',
    partner: '',
  });

  React.useEffect(() => {
    if (!open) {
      setValues({
        identification: '',
        partner: '',
      });
    }
  }, [open]);

  React.useEffect(() => {
    if (defaultValues) {
      setValues({ ...defaultValues });
    }
  }, [defaultValues]);

  const handleChange = (event: any): void => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  const handleChangeCpf = (event: any): void => {
    setValues({
      ...values,
      identification: cpfMask(event.target.value),
    });
  };

  const submit = (e: any): void => {
    e.preventDefault();

    if (!values.identification) {
      toast('Por favor, preencha os campos antes de submeter o formulário.', {
        type: 'error',
      });
      return;
    }

    handleSubmit({
      ...values,
      identification: removeMask(values.identification),
    });
  };

  return (
    <Modal open={open}>
      <StyledBox>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          {title}
        </Typography>
        <FormLoginStyled>
          <Div9>Identificação</Div9>
          <SimpleInput
            placeholder={'CPF'}
            name="identification"
            onChange={handleChangeCpf}
            value={values.identification}
          />

          <NewSelect
            name="partner"
            label="Parceiro *"
            options={partners?.map((item: any) => ({
              value: item._id,
              label: `${item.name} - ${item.email}`,
            }))}
            value={values.partner}
            onChange={handleChange}
          />

          <StyledContentButtons>
            <div style={{ width: '45%' }}>
              <Button
                onClick={handleClose}
                disabled={isLoading}
                style={{ marginTop: 10 }}
              >
                {isLoading ? (
                  <CircularProgress size={20} color="inherit" />
                ) : (
                  <span>Cancelar</span>
                )}
              </Button>
            </div>
            <div style={{ width: '45%' }}>
              <SubmitButton
                onClick={submit}
                disabled={isLoading}
                style={{ marginTop: 10 }}
              >
                {isLoading ? (
                  <CircularProgress size={20} color="inherit" />
                ) : (
                  <span>Salvar</span>
                )}
              </SubmitButton>
            </div>
          </StyledContentButtons>
        </FormLoginStyled>
      </StyledBox>
    </Modal>
  );
};

export default ModalAddWhitelist;
