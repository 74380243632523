import styled from 'styled-components';

export const StyledWhitelistItem = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '16px',
  marginBottom: 16,
  borderRadius: 8,
  color: '#878a99',
  boxShadow: '0px 0px 8px 1px rgba(0, 0, 0, 0.25)',
});

export const StyledWhitelistNoItem = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '32px',
  marginBottom: 16,
  borderRadius: 8,
  boxShadow: '0px 0px 8px 1px rgba(0, 0, 0, 0.25)',
  textAlign: 'center',
});

export const StyledWhitelistIcon = styled('div')({
  cursor: 'pointer',
  '&:hover': {
    color: '#f00',
  },
});

export const Div = styled.div`
  background-color: #fff;
  min-height: calc(100vh - 55px);
  padding: 33px 126px;
  font-family: 'Inter', sans-serif;
  display: flex;
  justify-content: center;

  @media (max-width: 991px) {
    padding: 10px 20px;
  }
`;

export const Div2 = styled.div`
  display: flex;
  width: 100%;
  max-width: 1200px;
  flex-direction: column;
  margin-bottom: 50px;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

export const StyledRequestsContainer = styled.div`
  width: 100%;
  border: 1px solid #e9ebec;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2),
    0px 0px 0px 0px rgba(0, 0, 0, 0.19);

  @media (max-width: 991px) {
    margin-top: 40px;
  }
`;

export const StyledRequestsContainerTable = styled.div`
  width: 100%;

  @media (max-width: 991px) {
    margin-top: 40px;
  }
`;

export const StyledLoading = styled.div`
  margin-top: 50px;
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 200px;
  padding: 30px;
  box-sizing: border-box;
  gap: 40px;
  color: #3377cc;
  font-weight: 500;
  @media (max-width: 991px) {
    margin-top: 40px;
  }
`;

export const StyleContentTitleContainer = styled.div`
  padding: 14px;
  border-bottom: 2px solid #e9ebec;
  font-family: 'Inter', sans-serif;
`;

export const StyledContentContainer = styled.div`
  padding: 52px 14px 16px;
  @media (max-width: 991px) {
    padding: 40px 8px 12px;
  }
`;

export const StyledAreaContainer = styled.div`
  border: 2px dashed #e9ebec;
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: pre;
  flex-direction: column;
  width: 100%;
  padding: 26px 0px;

  &:hover {
    cursor: pointer;
    border: 2px solid #79cc72;
  }
`;

export const StyledPlaneContainer = styled('div')({
  '> svg': {
    width: '127px',
  },
});

export const StyledSpanContent = styled.span`
  width: 100%;
  text-align: center;
  font-family: 'Inter', sans-serif;
`;

export const StyledContentRequestItem = styled.span`
  color: #878a99;
`;

export const StyledContentSearch = styled.span`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  padding-bottom: 20px;
`;

export const StyledContentFilterStatus = styled.div`
  margin-top: 8px;
`;

export const StyledHeaderTitlePage = styled.div`
  font-family: 'Inter', sans-serif;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  padding-bottom: 50px;
  margin-top: 16px;
`;

export const StyledContentButtonsHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

export const StyledContentPaymentField = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
