/* eslint-disable no-underscore-dangle */
import * as React from 'react';

import { Box, Tab, Tabs } from '@mui/material';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Div2, Div, StyledHeaderTitlePage } from './styles';

import NewLoggedHeader from '../../components/NewLoggedHeader';
import LandingPage from './LandingPage';
import Promotions from './Promotions';
import UserWhitelist from './UserWhitelist';

const Settings = (): React.ReactElement => {
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();

  const tab = searchParams.get('tab');
  const [value, setValue] = React.useState(tab ? Number(tab) : 0);

  React.useEffect(() => {
    if (tab) {
      setValue(Number(tab));
    }
  }, [tab]);

  const setQueryParam = (key: string, value: string | number) => {
    const newParams = new URLSearchParams(searchParams);
    newParams.set(key, value.toString());
    navigate(`?${newParams.toString()}`);
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);

    setQueryParam('tab', newValue);
  };

  return (
    <Div>
      <Div2>
        <NewLoggedHeader />

        <StyledHeaderTitlePage>
          <h1>Configurações</h1>
        </StyledHeaderTitlePage>

        <Box
          sx={{
            borderBottom: 1,
            borderColor: 'divider',
          }}
        >
          <Tabs value={value} onChange={handleChange} variant="fullWidth">
            <Tab label="Landing page" />
            <Tab label="Promoções" />
            <Tab label="Whitelist" />
          </Tabs>
        </Box>
        <div hidden={value !== 0}>
          <LandingPage />
        </div>
        <div hidden={value !== 1}>
          <Promotions />
        </div>
        <div hidden={value !== 2}>
          <UserWhitelist />
        </div>
      </Div2>
    </Div>
  );
};

export default Settings;
