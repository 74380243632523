import * as React from 'react';
import { CircularProgress, Modal, Typography } from '@mui/material';

import { toast } from 'react-toastify';
import {
  Div18,
  Div20,
  Div25,
  Div28,
  FormLoginStyled,
  StyledBox,
  StyledContentButtons,
} from './styles';
import SubmitButton, { Button } from '../../../../components/Button';
import AttachmentButton from '../../../../components/AttachmentButton';
import useAuthenticate from '../../../../recoil/hooks/authenticate';
import { uploadFileWhitelist } from '../../../../api/userwhitelist';

interface AttachmentFile {
  id: string;
  name: string;
}

interface Props {
  open: boolean;
  title: string;
  handleClose: () => void;
  handleSubmit: () => void;
}

const ModalImportWhitelist = ({
  open,
  title,
  handleClose,
  handleSubmit,
}: Props): React.ReactElement => {
  const { authenticate } = useAuthenticate();

  const [isLoading, setIsLoading] = React.useState(false);

  const [files, setFiles] = React.useState<AttachmentFile[]>([]);

  React.useEffect(() => {
    if (!open) {
      setFiles([]);
    }
  }, [open]);

  const submit = (e: any): void => {
    e.preventDefault();

    handleSubmit();
  };

  const handleUploadFile = async (filesToUpload: FileList): Promise<void> => {
    try {
      setIsLoading(true);

      const result = await uploadFileWhitelist(
        filesToUpload,
        authenticate.token
      );

      setFiles([...files, ...result.data]);
    } catch (error: any) {
      toast(
        error?.response?.data?.message ||
          'Não foi possível fazer o upload do arquivo. Por favor, tente novamente!',
        {
          type: 'error',
        }
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal open={open}>
      <StyledBox>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          {title}
        </Typography>
        <FormLoginStyled>
          <React.Fragment>
            <Div18>
              <Div20>
                Importe uma lista de identificações para a whitelist
              </Div20>
            </Div18>
            <Div25>
              <AttachmentButton
                label="Anexar arquivo .xlsx*"
                disabled={isLoading}
                handleChange={handleUploadFile}
                acceptTypes=".xlsx"
              />
              {files?.length ? (
                <Div28>
                  {files?.length > 1
                    ? `${files.length} arquivos adicionados`
                    : '1 arquivo adicionado'}
                </Div28>
              ) : (
                <Div28>Nenhum arquivo adicionado</Div28>
              )}
            </Div25>
          </React.Fragment>
          <StyledContentButtons>
            <div style={{ width: '45%' }}>
              <Button onClick={handleClose} disabled={isLoading}>
                <span>Fechar</span>
              </Button>
            </div>
            <div style={{ width: '45%' }}>
              <SubmitButton onClick={submit} disabled={isLoading}>
                {isLoading ? (
                  <CircularProgress size={20} color="inherit" />
                ) : (
                  <span>Finalizar</span>
                )}
              </SubmitButton>
            </div>
          </StyledContentButtons>
        </FormLoginStyled>
      </StyledBox>
    </Modal>
  );
};

export default ModalImportWhitelist;
