/* eslint-disable no-underscore-dangle */
import * as React from 'react';

import { toast } from 'react-toastify';
import Lottie from 'react-lottie';
import AddIcon from '@mui/icons-material/Add';
import UploadIcon from '@mui/icons-material/Upload';
import ClearIcon from '@mui/icons-material/Clear';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { FormControlLabel, Switch } from '@mui/material';
import {
  StyledLoading,
  StyledHeaderTitlePage,
  StyledContentButtonsHeader,
  StyledWhitelistItem,
  StyledWhitelistIcon,
  StyledWhitelistNoItem,
} from './styles';

import useAuthenticate from '../../../recoil/hooks/authenticate';
import animationData from './loading.json';

import { ButtonWithIcon } from '../../../components/Button';

import SearchInput from '../../../components/SearchInput';
import ModalConfirm from '../../../components/ModalConfirm';
import {
  getAllUserwhitelistRequest,
  handleChangeWhitelistStatusRequest,
  handleDeleteWhitelistRequest,
  submitAddWhitelist,
} from '../../../api/userwhitelist';
import ModalAddWhitelist from './ModalAddWhitelist';
import {
  StyledFormGroup,
  StyledLabelSwitch,
} from '../../../components/FormSettingsLandingPage/styles';
import ModalImportWhitelist from './ModalImportWhitelist';
import { getAllUsers } from '../../../api/request';
import { cpfMask } from '../../../utils/cpf';

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

const UserWhitelist = (): React.ReactElement => {
  const [modalConfirm, setModalConfirm] = React.useState({
    open: false,
    title: '',
    description: '',
    onClose: () => null,
    onConfirm: () => null,
  });
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const searchQuqery = searchParams.get('search');

  const [isLoading, setIsLoading] = React.useState(true);

  const { authenticate } = useAuthenticate();

  const [isWhitelistEnabled, setWhitelistEnabled] = React.useState(false);

  const [search, setSearch] = React.useState(searchQuqery || '');

  const [whitelist, setUserWhitelist] = React.useState<any[]>([]);

  const [partners, setPartners] = React.useState([]);

  const [openModalAddWhitelist, setOpenModalAddWhitelist] =
    React.useState(false);

  const [openModalImport, setOpenModalImport] = React.useState(false);

  const setQueryParam = (key: string, value: string | number) => {
    const newParams = new URLSearchParams(searchParams);
    newParams.set(key, value.toString());
    navigate(`?${newParams.toString()}`);
  };

  const getPartners = React.useCallback(
    async (search?: string, filter?: string) => {
      try {
        setIsLoading(true);
        const data = await getAllUsers(authenticate.token, search, filter);

        setPartners(data.users);
      } catch (error) {
        toast(
          'Não foi possível realizar sua solicitação. Por favor, tente novamente!',
          {
            type: 'error',
          }
        );
      } finally {
        setIsLoading(false);
      }
    },
    [authenticate.token]
  );

  React.useEffect(() => {
    getPartners('', 'partner');
  }, [getPartners]);

  const getData = React.useCallback(
    async (search: string, isLoading = true) => {
      try {
        setIsLoading(isLoading);
        const data = await getAllUserwhitelistRequest(
          authenticate.token,
          search
        );

        setUserWhitelist(data.whitelist);

        setWhitelistEnabled(data.isWhitelistEnabled);
      } catch (error) {
        toast(
          'Não foi possível realizar sua solicitação. Por favor, tente novamente!',
          {
            type: 'error',
          }
        );
      } finally {
        setIsLoading(false);
      }
    },
    [authenticate.token]
  );

  React.useEffect(() => {
    getData(search);
  }, [getData, search]);

  const handleSubmitAdd = async (values: any) => {
    try {
      setIsLoading(true);

      setOpenModalAddWhitelist(false);

      await submitAddWhitelist(
        values.identification,
        values.partner,
        authenticate.token
      );

      toast('Identificação adicionada com sucesso!', {
        type: 'success',
      });

      getData(search);
    } catch (error: any) {
      let message =
        'Não foi possível realizar sua solicitação. Por favor, tente novamente!';
      if (error.response?.status === 409) {
        message = 'Identificação já cadastrada!';
      }
      toast(message, {
        type: 'error',
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteItem = async (id: any) => {
    try {
      await handleDeleteWhitelistRequest(id, authenticate.token);

      getData(search, false);
    } catch (error) {
      toast(
        'Não foi possível realizar sua solicitação. Por favor, tente novamente!',
        {
          type: 'error',
        }
      );
    }
  };

  const handleChangeWhitelistStatus = async (enabled: boolean) => {
    try {
      await handleChangeWhitelistStatusRequest(enabled, authenticate.token);

      getData(search);

      const message = enabled
        ? 'Whitelist habilitada com sucesso!'
        : 'Whitelist desabilitada com sucesso!';

      toast(message, {
        type: 'success',
      });
    } catch (error) {
      toast(
        'Não foi possível realizar sua solicitação. Por favor, tente novamente!',
        {
          type: 'error',
        }
      );
    }
  };

  const handleDeleteItemConfirm = async (id: any) => {
    setModalConfirm({
      open: true,
      title: 'Deletar identificação',
      description:
        'A identificação será deletada e não acessará o sistema. Deseja continuar? ',
      onClose: () => {
        setModalConfirm((prev) => ({ ...prev, open: false }));
        return null;
      },
      onConfirm: () => {
        setModalConfirm((prev) => ({ ...prev, open: false }));
        handleDeleteItem(id);
        return null;
      },
    });
  };

  const handleChangeWhitelistStatusConfirm = async (enabled: boolean) => {
    setModalConfirm({
      open: true,
      title: enabled ? 'Habilitar whitelist' : 'Desabilitar whitelist',
      description: enabled
        ? 'Deseja habilitar a whitelist?'
        : 'Deseja desabilitar a whitelist?',
      onClose: () => {
        setModalConfirm((prev) => ({ ...prev, open: false }));
        setWhitelistEnabled(!enabled);
        return null;
      },
      onConfirm: () => {
        setModalConfirm((prev) => ({ ...prev, open: false }));
        handleChangeWhitelistStatus(enabled);
        return null;
      },
    });
  };

  return (
    <React.Fragment>
      <ModalAddWhitelist
        open={openModalAddWhitelist}
        handleClose={() => setOpenModalAddWhitelist(false)}
        handleSubmit={handleSubmitAdd}
        isLoading={false}
        title="Adicionar identificação"
        defaultValues={{ identification: '', partner: '' }}
        partners={partners}
      />

      <ModalImportWhitelist
        open={openModalImport}
        handleClose={() => {
          setOpenModalImport(false);
          getData(search);
        }}
        handleSubmit={() => {
          setOpenModalImport(false);
          getData(search);
        }}
        title="Importar whitelist"
      />
      <div>
        <StyledHeaderTitlePage>
          <h1></h1>
          <StyledContentButtonsHeader>
            <React.Fragment>
              <ButtonWithIcon
                label="Importar"
                icon={<UploadIcon />}
                onClick={() => setOpenModalImport(true)}
              />
              <ButtonWithIcon
                label="Adicionar"
                icon={<AddIcon />}
                onClick={() => setOpenModalAddWhitelist(true)}
              />
              <SearchInput
                handleSubmit={(value) => {
                  setSearch(value);
                  setQueryParam('search', value);
                }}
                defaultValue={search}
              />
            </React.Fragment>
          </StyledContentButtonsHeader>
        </StyledHeaderTitlePage>

        {isLoading && (
          <StyledLoading>
            <Lottie options={defaultOptions} height={150} width={150} />
            <div>Carregando</div>
          </StyledLoading>
        )}

        {!isLoading && (
          <StyledFormGroup>
            <FormControlLabel
              control={
                <Switch
                  checked={isWhitelistEnabled}
                  onChange={(event) => {
                    setWhitelistEnabled(event.target.checked);
                    handleChangeWhitelistStatusConfirm(event.target.checked);
                  }}
                />
              }
              label={
                <StyledLabelSwitch>
                  {isWhitelistEnabled
                    ? 'Whitelist habilitada'
                    : 'Whitelist desabilitada'}
                </StyledLabelSwitch>
              }
            />
          </StyledFormGroup>
        )}

        {!isLoading &&
          whitelist?.map((item, index) => (
            <StyledWhitelistItem key={index}>
              <div>
                <div>
                  <span>CPF: </span>
                  <b>{cpfMask(item.identification)}</b>
                </div>
                <div>
                  <span>Parceiro: </span>
                  {`${item.partner?.name} <${item.partner.email}>`}
                </div>
              </div>
              <StyledWhitelistIcon
                onClick={() => handleDeleteItemConfirm(item._id)}
              >
                <ClearIcon color="error" />
              </StyledWhitelistIcon>
            </StyledWhitelistItem>
          ))}

        {!isLoading && !whitelist?.length && (
          <StyledWhitelistNoItem>
            Nenhuma identificação adicionada na whitelist
          </StyledWhitelistNoItem>
        )}
      </div>
      <ModalConfirm {...modalConfirm} />
    </React.Fragment>
  );
};

export default UserWhitelist;
